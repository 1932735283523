import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: process.env.API_URL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;

export function getOptions(options) {
  const params = options.map(function(el, idx) {
      return 'id[' + idx + ']=' + el;
  }).join('&');

  return api.get(`/option?with_values=1&${params}`);
}

export function getDefaultValues(apiPath, data, name, multiple, fieldId) {
  if (!data || !data[name]) return undefined;

  if (Array.isArray(data[name]) && data[name].length === 0) return undefined;

  const apiJoin = apiPath.search("\\?") === -1 ? '?' : '&';

    const ids = [];

    if (data[name]) {
      if (multiple) {
        data[name].forEach(row => {
          ids.push(`${fieldId}[]=${row[fieldId]}`);
        });
      } else {
        ids.push(`${fieldId}=${data[name]}`);
      }
    }

    return api.get(`${apiPath}${apiJoin}${ids.join('&')}`);
}