import { navigate } from "gatsby-link";

const isBrowser = typeof window !== "undefined";

export const TOKEN_KEY = "@criare-Token";
export const USER_KEY = "@criare-User";
export const isAuthenticated = () => isBrowser && localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => isBrowser && localStorage.getItem(TOKEN_KEY);
export const login = token => {
  if (isBrowser) {
    localStorage.setItem(TOKEN_KEY, token);
  }
};

export const setCurrentUser = user => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getCurrentUser = () => isBrowser && JSON.parse(localStorage.getItem(USER_KEY));
export const hasCurrentUser = () => isBrowser && localStorage.getItem(USER_KEY) !== null;

export const logout = () => {
  if (isBrowser) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  }
};

export const hasPermission = (permission) => {
  const user = getCurrentUser();
  
  return user?.permissions?.includes(permission);
};

export const ifNotHaveAccessRedirect = (permission) => {
  const user = getCurrentUser();

  if (!user?.permissions?.includes(permission)) {
    navigate && navigate('/');
    return true;
  }
};
